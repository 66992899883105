<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="tree"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
				row-key="id"
				:tree-props="{children: 'children'}"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.id }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="等级名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="是否默认" min-width="110" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.is_def === 1" size="mini" type="success">是</el-tag>
					<el-tag v-if="scope.row.is_def === 2" size="mini" type="danger">否</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
<!-- 					<el-tooltip class="item" effect="dark" content="下载模版" placement="top" v-if="scope.row.id > 2 && scope.row.id != 99">
					  <el-button type="primary" icon="el-icon-download" size="mini" circle @click="downloadTemplate(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="导入" placement="top" v-if="scope.row.id > 2 && scope.row.id != 99">
					  <el-upload
					    class="upload-demo d-inline-block mx-1"
					    ref="upload"
					    action="#"
					    :on-change="(file, fileList) => {importExcel(file, scope.row.id)}"
					    :on-success="importSuccess"
					    :on-error="importError"
					    :before-upload="beforeImportUpload"
					    :limit="1"
					    :multiple="false"
					    :show-file-list="false"
					    accept=".xls, .xlsx"
					    :auto-upload="false"
						:data="{id:scope.row.id}"
					    >
					    <el-button slot="trigger" icon="el-icon-upload2" size="mini" type="warning" circle></el-button>
					  </el-upload>
					</el-tooltip> -->
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="上级类型" prop="pid">
			     <el-select v-model="form.pid" placeholder="请选择上级类型" size="medium" class="w-100">
			       <el-option label="顶级类型" :value="0"></el-option>
			       <el-option v-for="(item,index) in list" :key="index"
			         :label="item | tree"
			         :value="item.id">
			       </el-option>
			     </el-select>
				</el-form-item>
		    <el-form-item label="类型名称" prop="name">
		      <el-input v-model="form.name" placeholder="请输入类型名称" type="text" clearable />
		    </el-form-item>
		    <el-form-item  label="默认类型">
		       <el-radio-group v-model="form.is_def">
		       	<el-radio :label="1">是</el-radio>
		       	<el-radio :label="2">否</el-radio>
		       </el-radio-group>
		     </el-form-item>
		   <el-form-item label="类型状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
			<el-form-item label="类型序" prop="sort">
			  <el-input v-model="form.sort" placeholder="请输入排序" type="number" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user_grade',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					pid:0,
					name:'',
					is_def:2,
					status:1,
					sort:100,
				},
				rules:{
					name: [
					  { required: true, message: '请输入等级名称', trigger: 'blur' }
					],
				},
				tree:[],
			}
		},
		mounted() {
			
		},
		methods:{
			getList() {
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = res.total
				  this.list = res.data
				  this.tree = res.tree
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
			},
			reset() {
			  this.form = {
			   pid:0,
			   name:'',
			   is_def:2,
			   status:1,
			   sort:100,
			  }
			  this.resetForm('form')
			},
			downloadTemplate(item){
				this.axios.get('/manage/user/downloadOtherTemplate',{
					responseType: 'arraybuffer',
					params:{
						grade:item.id
					}
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = item.name+'导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e,id){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				formdata.append('id', id)
				this.axios.post('/manage/user/importOtherExport',formdata).then(res=>{
					console.log(res)
					this.$message.success('导入成功')
					// if(res.status){
					// 	this.$message.success('导入成功')
					// }else{
					// 	this.$message.success('导入失败')
					// }
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
			importSuccess(e){
				console.log(e)
				// 上传成功之后清除历史记录;否则只能上传一次
				this.$refs.upload.clearFiles()
			},
			importError(e){
				console.log(e)
				this.$refs.upload.clearFiles()
			},
		},
		filters: {
		  tree(item) {
			if (item.level == 0) {
			  return item.name
			}
			let str = ''
			for (let i = 0; i < item.level; i++) {
			  str += i == 0 ? '|--' : '--'
			}
			return str + ' ' +item.name;
		  }
		},
	}
</script>

<style>
</style>